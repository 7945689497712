import PropTypes from "prop-types";
import React, { useCallback } from "react";
import {
  ArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  NumberInput,
  BooleanInput,
  useNotify
} from "react-admin";
import { useField } from "react-final-form";
import AceInput from "../../components/AceInput";
import FormDataContent from "../../components/FormDataContent";
import { required, negativeNumber, minValue0, number } from "../../tools";
import { useStyles } from "./EditForm";
import useOperatorsQB from "../../hooks/useOperatorsQB";

const CampaignConfigs = ({ source = "" }) => {
  const {
    input: { value: isCampaignEnable, onChange:onChangeCampaignIsEnable },
  } = useField(`campaign_enable`);
  const {
    input: { value: isKiosqV2Enable, onChange:onChangeKiosqV2Enable },
  } = useField(`kiosq_v2_enable`);

  const handleOnChangeKiosqV2Enable = (value) =>{
    if(!value){
      onChangeCampaignIsEnable(false);
    }
    onChangeKiosqV2Enable(value);
  }
  const handleOnChangeCampaignIsEnable = (value) =>{
    if(value){
      onChangeKiosqV2Enable(true)
    }
    onChangeCampaignIsEnable(value)
  }

  return (
    <>
      <BooleanInput source={`kiosq_v2_enable`} label="Use kiosq v2 on site" onChange={handleOnChangeKiosqV2Enable}/>
      <BooleanInput source={`campaign_enable`} label="Use campaigns on site" onChange={handleOnChangeCampaignIsEnable} />
      {isCampaignEnable && (
        <FormDataContent dataContentTitle="Campaign Configs">
          <ArrayInput
            source={`campaigns_query_options`}
            label={`Custom Query Options`}
          >
            <SimpleFormIterator>
              <ConfigForm showQBSettings />
            </SimpleFormIterator>
          </ArrayInput>
        </FormDataContent>
      )}
    </>
  );
};

CampaignConfigs.propTypes = {
  source: PropTypes.string,
};

export const camelCaseWithPrefixSuffix = (str, prefix = "", suffix = "") => {
  return (
    prefix +
    str
      .replace(/(?:^|\s+)(\w)/g, function (match, p1) {
        return p1.toUpperCase();
      })
      .replace(/\s+/g, "") +
    suffix
  );
};

export const ConfigForm = ({
  source = "",
  defaultValue = "",
  showQBSettings = false,
}) => {
  const {
    input: { value: inputTypeValue },
  } = useField(`${source}input_type`);

  const {
    input: { value: inputOperators },
  } = useField(`${source}operators`);

  const {
    input: { onChange: changeName },
  } = useField(`${source}name`, { defaultValue: "" });

  const {
    input: { value: showStoreQBSettings },
  } = useField(`${source}use_qb_setting`, { defaultValue: showQBSettings });

  const { operators, handleChangeOperators } = useOperatorsQB();
  const classes = useStyles();

  const handleChangeLabel = useCallback((e) => {
    //slugify the label
    changeName(camelCaseWithPrefixSuffix(e.target.value));
  });

  return (
    <div className={classes.flexDirectionColumn}>
      <TextInput
        className={classes.flexInput}
        label="Key"
        disabled
        source={`${source}name`}
      />
      <TextInput
        className={classes.flexInput}
        label="Label"
        onChange={handleChangeLabel}
        helperText="ex: Blueconic enabled"
        required
        validate={[required]}
        resettable
        source={`${source}label`}
      />
      <AceInput
        name={`${source}script`}
        source={`${source}script`}
        placeholder={`() => {return true;}`}
        defaultValue={`() => {return true;}`}
        label={"Script"}
      />
      <BooleanInput
        source={`${source}use_qb_setting`}
        label="Use Query Builder settings"
      />
      {showStoreQBSettings && (
        <>
          <NumberInput
            helperText="10 to 100"
            source={`${source}priority`}
            className={classes.flexInput}
            label="Segment Score Priority"
            required
            defaultValue={10}
            validate={[required, number, minValue0]}
            warn={[negativeNumber]}
          />
          <SelectInput
            label="Input Type"
            source={`${source}input_type`}
            className={classes.flexInput}
            required
            validate={[required]}
            resettable
            choices={[
              { id: "text", name: "Text" },
              { id: "number", name: "Number" },
              { id: "date", name: "Date" },
              { id: "multiselect", name: "Multiselect" },
              { id: "checkbox", name: "Checkbox" },
            ]}
            onChange={handleChangeOperators}
          />
          {inputTypeValue === "multiselect" && (
            <ArrayInput
              source={`${source}multiselect_values`}
              label={`MultiSelect Values`}
              className={classes.flexInput}
            >
              <SimpleFormIterator>
                <TextInput label="Value" source={`multiselect_value`} />
              </SimpleFormIterator>
            </ArrayInput>
          )}
          <SelectArrayInput
            label="Operators"
            required
            validate={[required]}
            className={classes.flexInput}
            source={`${source}operators`}
            choices={operators}
            />
          {inputOperators.length > 0 && inputOperators.some((o) => ["existOneBy","notexistOneBy"].includes(o)) && (
            <TextInput label="by Key" source={`${source}key_val_exist_key`} />
          )}
        </>
      )}
    </div>
  );
};

export default CampaignConfigs;
