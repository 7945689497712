import { useGetOne } from "react-admin";
import PropTypes from 'prop-types';
import { defaultOperators } from "react-querybuilder";
import useSettings from "../../hooks/useSettings";

const defaultCampaignsQueryOptions = [
  {
    name: "campaign_start_date",
    label: "Start Date",
    inputType: "date",
    segment_score: 10,
    operators: defaultOperators.filter((op) =>
      [">", ">=", "<", "<="].includes(op.name)
    ),
  },
  {
    name: "campaign_end_date",
    label: "End Date",
    inputType: "date",
    segment_score: 10,
    operators: defaultOperators.filter((op) =>
      [">", ">=", "<", "<="].includes(op.name)
    ),
  },
];

const formatCustomOptions = (options = []) => {
  if (!options && !Array.isArray(options)) return [];
  return options.map((option) => {
    const {
      name,
      label,
      input_type,
      default_value,
      multiselect_values,
      key_val_exist_key,
      operators,
      segment_score = 0,
    } = option;
    const finalOptions = {
      name:`${name}${key_val_exist_key?`.${key_val_exist_key}`:``}`, //add key_val_exist_key to the name to handle format CEL in querybuilder
      label:`${label} ${key_val_exist_key?`(by ${key_val_exist_key})`:``}`, //add key_val_exist_key to the label when using existOneBy operator in querybuilder
      segment_score:segment_score,
      ...(["multiselect", "checkbox", "select"].includes(input_type)
        ? { valueEditorType: input_type }
        : { inputType: input_type }),
      ...(["multiselect", "select"].includes(input_type)
        ? {
            values: [
              {
                label,
                options: multiselect_values?.map((s) => ({
                  name: s.multiselect_value,
                  label: s.multiselect_value,
                })),
              },
            ],
          }
        : {}),
      ...(default_value && { defaultValue: default_value }),
      operators: operators?.map((op) => {
        return { name: op, label: op };
      }),
    };
    return finalOptions;
  });
};
const useCustomQueryOptions = (siteId = "") => {

  let sitesConfigsCustomOptions = [];
  let settingsCustomOptions = [];
  const dataJSVariables = useSettings("js_variables_list");
  
  // if(!siteId) {
    const { data: dataSiteConfig } = useGetOne("sites-configs", siteId);
    sitesConfigsCustomOptions = formatCustomOptions(
      dataSiteConfig?.campaigns_query_options
    );
  // };

  // if(!dataJSVariables){
    settingsCustomOptions = formatCustomOptions(
      dataJSVariables?.js_variables_global
      );
  // }

  return [
    ...defaultCampaignsQueryOptions,
    ...sitesConfigsCustomOptions,
    ...settingsCustomOptions,
  ];
};

formatCustomOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    segment_score: PropTypes.number.isRequired,
    input_type: PropTypes.oneOf(["text", "number", "textarea", "multiselect", "checkbox", "select"]).isRequired,
    default_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiselect_values: PropTypes.arrayOf(
      PropTypes.shape({
        multiselect_value: PropTypes.string.isRequired
      })
    ),
    operators: PropTypes.arrayOf(PropTypes.string)
  }))
};

export default useCustomQueryOptions;
