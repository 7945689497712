import React from 'react';
import UserFilter from './Filter';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Pagination,
  ExportButton,
  TopToolbar,
  useListContext,
  DeleteWithConfirmButton,
  useRecordContext,
} from 'react-admin';
import PaywallGrantedSiteField from '../../components/PaywallGrantedSiteField';
import PaywallTrialSiteField from '../../components/PaywallTrialSiteField';
import UserExporter from '../../components/UserExporter';

const UserListActions = ({ basePath }) => {
    const { total } = useListContext();
    return (
        <TopToolbar>
            <UserFilter context="button" />
            <ExportButton maxResults={total} title="1000 users MAX" />
        </TopToolbar>
    )
};

const UserPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const UserList = (props) => {

    return (
        <List actions={<UserListActions />} perPage={10} filters={<UserFilter />} filterDefaultValues={{ paywall: 'void', trial: 'void' }} exporter={UserExporter} pagination={<UserPagination />} {... props} >
            <Datagrid rowClick="show">
                <TextField source="email" />
                <DateField label= "Created at" source="created_at" />
                <PaywallGrantedSiteField label= "Has grace period on" />
                <PaywallTrialSiteField label= "Has trial period on" />
                <EditButton />
                <DeleteWithConfirmButton
                    confirmContent="This will delete the user from all Future sites : Cycling news, The Week etc.. Are you sure ?"
                />
            </Datagrid>
        </List>
    );
};

export default UserList;
