import React, { useState } from "react";
import { TextInput, BooleanInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import FormDataContent from "./FormDataContent";
import PremiumLayerManager from "./PremiumLayerManager";
import RegwallAuthLayerManager from "./RegwallAuthLayerManager";
import MainLayerManager from "./MainLayerManager";
import StickyLayerManager from "./StickyLayerManager";
import Layer from "./Layer";
import { Field, useFormState, useField } from "react-final-form";
import StickyRegwallAuthLayerManager from "./StickyRegwallAuthLayerManager";

const useStyles = makeStyles({
  long_input: {
    width: "65%",
    marginRight: "10%",
  },
});
const LayersManager = ({ field = "", ...props }) => {
  const source = field ? `${field}.` : "";
  const classes = useStyles();
  const { values: formdata } = useFormState();
  const formdataSource = field ? formdata[field] : formdata;

  const {
    input: {
      onChange: handleChangeIsPremiumEnabledVal,
    },
  } = useField(
    `${source}template_main_premium`
  );
  const {
    input: {
      onChange: handleChangeIsPremiumCTEnabledVal,
    },
  } = useField(
    `${source}main_layer_premium_enabled`
  );

  const [isCollapsePremiumLayer, setIsCollapsePremiumLayer] = useState((formdataSource && formdataSource.template_main_premium) || false);
  const [isCollapsePremiumCTLayer, setIsCollapsePremiumCTLayer] = useState((formdataSource && formdataSource.main_layer_premium_enabled) || false);

  const handleChangeIsPremiumEnabled = (val) => {
    setIsCollapsePremiumLayer(val);
    if (!val) {
      setIsCollapsePremiumCTLayer(false);
      handleChangeIsPremiumCTEnabledVal(false);
    }
  };

  const handleChangeIsPremiumCTEnabled = (val) => {
    setIsCollapsePremiumCTLayer(val);
    if (val) {
      setIsCollapsePremiumLayer(false);
      handleChangeIsPremiumEnabledVal(true);
    }
    if (!val) {
      setIsCollapsePremiumLayer(true);
    }
  };

  return (
    <div>
      <FormDataContent dataContentTitle="Main layer">
        <BooleanInput
          source={`${source}main_layer_enabled`}
          label={"Use custom Template"}
          {...(field !== "overrides" && { defaultValue: false })}
        />
        {formdataSource?.main_layer_enabled && (
          <Field
            name={"Main layer"}
            source={`${source}main_layer`}
            height="100%"
            width="100%"
            component={Layer}
          />
        )}

        {!formdataSource?.main_layer_enabled && <MainLayerManager source={source} field={field} />}
      </FormDataContent>

      <FormDataContent dataContentTitle="Main layer premium">
      <BooleanInput
        helperText="To add a specific layer for Premium content"
        label="Add Premium barrier"
        source={`${source}template_main_premium`}
        onChange={handleChangeIsPremiumEnabled}
      />
        {isCollapsePremiumLayer && (
          <PremiumLayerManager field={field} source={source} premiumManagementExpanded={isCollapsePremiumLayer} />
        )}
        <BooleanInput
          source={`${source}main_layer_premium_enabled`}
          onChange={handleChangeIsPremiumCTEnabled}
          label={"Use custom Template"}
          {...(field !== "overrides" && { defaultValue: false })}
        />
        {isCollapsePremiumCTLayer && (
          <Field
            name={"Main layer premium"}
            source={`${source}main_layer_premium`}
            height="100%"
            width="100%"
            component={Layer}
          />
        )}
      </FormDataContent>

      {formdataSource &&
        formdataSource["wall_type"] === "regwallAuthPaywall" && (
          <FormDataContent dataContentTitle="Regwall layer">
            <BooleanInput
              source={`${source}regwall_layer_enabled`}
              label={"Use custom Template"}
              {...(field !== "overrides" && { defaultValue: false })}
            />
            {formdataSource?.regwall_layer_enabled && (
              <Field
                name={"Regwall layer"}
                source={`${source}regwall_layer`}
                height="100%"
                width="100%"
                component={Layer}
              />
            )}
            {!formdataSource?.regwall_layer_enabled && (
              <RegwallAuthLayerManager />
            )}
          </FormDataContent>
        )}
      <BooleanInput
        helperText="Display a sticky layer on the bottom of the page when scrolling down after the main barrier"
        label="Enable sticky template"
        source={`${source}template_sticky`}
        />
      {formdataSource?.template_sticky &&
        formdataSource?.template_sticky === true && (
          <FormDataContent dataContentTitle="Sticky layer">
            <BooleanInput
              source={`${source}sticky_layer_enabled`}
              label={"Use custom Template"}
              {...(field !== "overrides" && { defaultValue: false })}
              />
            {formdataSource.sticky_layer_enabled && (
              <Field
                source={`${source}sticky_layer`}
                name={"Sticky layer"}
                height="100%"
                width="100%"
                component={Layer}
              />
            )}
            {!formdataSource.sticky_layer_enabled && <StickyLayerManager />}
          </FormDataContent>
        )}
      {formdataSource &&
        formdataSource.template_sticky &&
        formdataSource.template_sticky === true &&
        formdataSource["wall_type"] === "regwallAuthPaywall" && (
          <FormDataContent dataContentTitle="Sticky regwall layer">
            <BooleanInput
              source={`${source}sticky_regwall_layer_enabled`}
              label={"Use custom Template"}
              {...(field !== "overrides" && { defaultValue: false })}
            />
            {formdataSource?.sticky_regwall_layer_enabled && (
              <Field
                name={"Sticky Regwall layer"}
                source={`${source}sticky_regwall_layer`}
                height="100%"
                width="100%"
                component={Layer}
              />
            )}
            {!formdataSource?.sticky_regwall_layer_enabled && (
              <StickyRegwallAuthLayerManager source={source} />
            )}
          </FormDataContent>
        )}
      <BooleanInput
        helperText="Display a dropdown menu in the header if available for this site"
        label="Enable header menu template"
        source={`${source}template_menu_header`}
      />
      {/* not showing for campaigns */}
      {field !== "overrides" && <FormDataContent dataContentTitle="Add specific css (Advanced)">
        <TextInput
          multiline
          minRows="10"
          className={classes.long_input}
          source={`${source}specific_css`}
        />
      </FormDataContent>
      }
    </div>
  );
};

export default LayersManager;
