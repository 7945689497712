import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Labeled,
  List,
  SortButton,
  TextField,
  TopToolbar,
  useAuthProvider
} from "react-admin";
import TextFieldControlled from "../../components/TextFieldControlled";
import TootltipControlled from "../../components/TooltipControlled";
import ListFilters from "./ListFilters";
const useStyles = makeStyles({
  logo_content: {
    "& img": {
      maxWidth: "100px",
    },
  },
});
const ListActions = (props) => {
  return (
    <TopToolbar>
      <SortButton fields={["priority"]} />
      <CreateButton />
      <ExportButton />
    </TopToolbar>
  );
};

const CampaignsList = (props) => {
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const [permissions, setPermissions] = useState();
  useEffect(async()=> {
    authProvider.getPermissions().then((permissions) => setPermissions(permissions))
  },[]);
  return (
    <List
      perPage={25}
      filters={<ListFilters />}
      filterDefaultValues={{ site_config_overrided: "fourfourtwo_en-gb" }}
      actions={<ListActions />}
      sort={{ field: "priority", order: "DESC" }}
      {...props}
    >
      <Datagrid>
        {/* <TextField source="id" /> */}
        <TextField source="name" />
        <TextField label={"Site"} source="site_config_overrided" />
        <TextFieldControlled
          label="Segment Score Priority"
          helperText={"Higher first"}
          name="priority"
          updateDataResource={`priority`}
        />
        <TextField source="campaign_start_date" />
        <TextField source="campaign_end_date" />
        <TextField
          source="campaign_query_builder.query_format"
          label={`Query`}
        />
        <EditButton />
        {permissions && permissions.includes('SuperAdmin') &&
          <Labeled label={"Pythia Ids"}>
          <TootltipControlled
            fieldsToRender={["site_config_overrided","guid"]} />
          </Labeled>
        }
      </Datagrid>
    </List>
  );
};

export default CampaignsList;
